<template>
  <div class="bg-grey-1">
    <Header />
    <div class="md:mx-44 md:my-16 m-4">
      <div class="flex flex-col gap-4">
        <h4 class="font-semibold text-2xl">Your Booking</h4>
        <div class="flex flex-col md:flex-row gap-8 relative">
          <aside class="md:w-64 block md:relative z-10 top-0 left-0 right-0">
            <div class="flex flex-col rounded-lg bg-white md:p-4 p-2 font-semibold" :class="[showAccordion.destination ? 'shadow-lg' : '']">
              <div class="flex flex-col rounded-lg bg-white font-semibold">
                <div class="flex flex-row justify-between items-center pb-4 border-b-2 border-grey-1">
                  <p class="px-2">Trip Type</p>
                </div>
                <div class="flex flex-col gap-3 pt-2 px-2">
                  <div class="flex flex-row gap-2">
                    <input type="radio" id="trip_type_safari" name="trip_type" class="cursor-pointer" value="safari" @click="$router.push({ name: 'ListPaket' })" />
                    <label for="trip_type_safari" class="cursor-pointer">Safari</label>
                  </div>
                  <div class="flex flex-row gap-2">
                    <input type="radio" id="trip_type_rental" name="trip_type" class="cursor-pointer" value="rental" checked/>
                    <label for="trip_type_rental" class="cursor-pointer">Rental</label>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center mt-8"
                @click="toggleDestinationAccordion"
                :class="[
                  showAccordion.destination
                    ? 'pb-4 border-b-2 border-grey-1'
                    : 'pb-0 border-none',
                ]"
              >
                <div
                  class="flex flex-row justify-between items-center w-full px-2"
                >
                  <p>Destination</p>
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-up"
                    class="text-primary"
                    v-if="showAccordion.destination"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-chevron-down"
                    class="text-primary"
                    v-else
                  />
                </div>
              </div>
              <div
                class="flex flex-col gap-3 pt-2 px-2"
                v-if="showAccordion.destination"
              >
                <div
                  class="flex flex-row justify-between items-center"
                  v-if="listKota.length > 0"
                  v-for="kota in listKota"
                  :key="kota.nama_wilayah"
                >
                  <div class="flex flex-row gap-2">
                    <input
                      type="radio"
                      :value="kota.id_wilayah"
                      :id="kota.nama_wilayah"
                      name="destination"
                      class="cursor-pointer"
                      :checked="idWilayah == kota.id_wilayah"
                      @click="updateCity(kota)"
                    />
                    <label class="font-bold cursor-pointer" :for="kota.nama_wilayah">{{
                      kota.nama_wilayah
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          <RentalEmpty v-if="(idWilayah == null || idWilayah == '')" />
          <div class="md:flex flex-row flex-grow gap-8" v-else>
            <div
              class="flex flex-col flex-grow gap-6 rounded-lg bg-white md:px-6 md:py-4 p-4 pt-10"
            >
              <div class="flex flex-col gap-2">
                <div class="flex flex-row justify-between items-center">
                  <p class="font-semibold">Select Merchant</p>
                  <!-- <select class="text-black font-normal text-sm" v-model="formData.toko">
                    <option :value="toko.id" class="" v-for="(toko, tokoidx) in listToko" :key="tokoidx">
                      {{toko.nama}}
                    </option>
                  </select> -->
                </div>
                <div class="flex flex-rowb gap-2">
                  <p
                    class="font-normal md:text-sm text-2xs md:px-6 px-2 py-1 rounded-lg cursor-pointer" :class="{'bg-primary text-white': formData.toko == dock.id_toko_jetski_rental, 'bg-white border border-gray-200 text-black': formData.toko != dock.id_toko_jetski_rental}" v-for="(dock, dockidx) in listToko" :key="dockidx" @click="selectDataToko(dock)"
                  >
                    {{ dock.nama_jetski_rental }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <p class="font-semibold">Select Time</p>
                <div class="flex flex-row md:gap-10 gap-4 items-center">
                  <div class="flex flex-col gap-1">
                    <p class="text-sm font-semibold">Start</p>
                    <div class="flex flex-row gap-2">
                      <div
                        class="bg-primary rounded-lg w-12 flex justify-center items-center"
                      >
                        <font-awesome-icon
                          icon="fa-regular fa-clock"
                          class="text-white text-lg"
                        />
                      </div>
                      <div class="md:px-4 px-2 py-1 rounded-md bg-gray-200">
                        <vue-timepicker
                          format="HH:00"
                          input-class="md:w-20 w-16 bg-transparent font-semibold border-transparent cursor-pointer"
                          v-model="timeStart"
                          input-width="80px"
                          fixed-dropdown-button
                          hide-clear-button
                          @input="handleTimePicker($event,'start')"
                        ></vue-timepicker>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-1">
                    <p class="text-sm font-semibold">End</p>
                    <div class="flex flex-row gap-2">
                      <div
                        class="bg-primary rounded-lg w-12 flex justify-center items-center"
                      >
                        <font-awesome-icon
                          icon="fa-regular fa-clock"
                          class="text-white text-lg"
                        />
                      </div>
                      <div class="md:px-4 px-2 py-1 rounded-md bg-gray-200">
                        <vue-timepicker
                          format="HH:00"
                          input-class="md:w-20 w-16 bg-transparent font-semibold border-transparent cursor-pointer"
                          v-model="timeEnd"
                          input-width="80px"
                          fixed-dropdown-button
                          hide-clear-button
                          @input="handleTimePicker($event,'end')"
                        ></vue-timepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <p class="font-semibold">Choose Date</p>
                <v-date-picker
                  ref="calendar"
                  is-expanded
                  v-model="formData.bookingDate"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  @dayclick="onDayClick"
                />
                <div
                  class="rounded-md border-2 border-yellow-f6 h-10 text-sm font-semibold flex justify-center items-center"
                >
                  <span v-if="formData.bookingDate == null || formData.jam_awal == null || formData.jam_akhir == null">Selected Time and Date First</span>
                  <span v-else>Avaiable stock is {{ availableStock }} on {{ $moment(formData.bookingDate).format('Do MMMM') }}</span>
                </div>
              </div>
              <div class="flex flex-col gap-2" v-if="formData.bookingDate != '' && formData.bookingDate != null">
                <p class="font-semibold">Rental Package</p>
                <div class="flex flex-row justify-between" v-for="(paket, paketidx) in listPaket" :key="paketidx" v-if="listPaket.length > 0">
                  <div class="flex flex-row gap-5">
                    <div class="h-16 md:w-22 2xl:w-28 w-16 flex-shrink-0">
                      <img
                        :src="paket.foto"
                        alt=""
                        class="object-contain rounded-md w-full h-full"
                      />
                    </div>
                    <div class="flex flex-col justify-between">
                      <div class="flex flex-col">
                        <p
                          class="font-semibold text-sm md:text-base text-primary"
                        >
                          {{ paket.nama }}
                        </p>
                        <p class="md:text-xs text-2xs font-medium text-gray-400 line-through" v-if="paket.harga != paket.harga_blm_diskon">
                          <b>{{ paket.harga_blm_diskon | toCurrency }}</b>
                        </p>
                        <p class="md:text-xs text-2xs font-medium">
                          <b>{{ paket.harga | toCurrency }}</b>/hour
                        </p>
                      </div>
                      <p class="md:text-xs text-2xs font-medium">
                        Available <b>{{ paket.stok }}</b> unit
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row flex-shrink-0 gap-2 items-end" v-if="paket.is_closed == false && paket.stok > 0">
                    <button
                      class="text-black h-8 w-8 flex justify-center items-center bg-yellow-400 rounded-md"
                      @click="minQty(paket)"
                    >
                      -
                    </button>

                    <input
                      type="number"
                      :value="paket.qty"
                      readonly
                      class="border border-gray-300 rounded-md h-8 md:w-12 w-8 text-center"
                    />

                    <button
                      class="text-black h-8 w-8 flex justify-center items-center bg-yellow-400 rounded-md"
                      @click="addQty(paket)"
                    >
                      +
                    </button>
                  </div>
                  <div class="flex flex-row flex-shrink-0 items-end font-bold text-red-600" v-else-if="paket.stok > 0">
                    NOT AVAILABLE
                  </div>
                  <div class="flex flex-row flex-shrink-0 items-end font-bold text-red-600" v-else>
                    SOLD OUT
                  </div>
                </div>
                <div v-else>
                  No Package
                </div>
              </div>
            </div>
            <div>
              <div
                class="flex flex-col rounded-md md:w-64 w-full mt-4 md:mt-0 bg-white"
              >
                <p
                  class="font-semibold pb-3 border-b-2 border-grey-1 mx-4 mt-4"
                >
                  Booking Summary
                </p>
                <p class="font-medium mx-4 mt-3 text-primary">Tripwe Rental</p>
                <div
                  class="flex flex-col gap-1 py-2 px-4 bg-grey-f7 text-xs font-medium"
                >
                  <p class="flex justify-between"><span>Selected Visit Date</span><span>{{ $moment(formData.bookingDate).format("DD MMM YYYY") }}</span></p>
                  <p class="flex justify-between"><span>Time</span><span>{{ formData.jam_awal.format("HH:mm") }} - {{ formData.jam_akhir.format("HH:mm") }}</span></p>
                  <p class="flex justify-between"><span>Dock</span><span class="text-primary underline"><a :href="mapUrl" target="_blank">{{ selectedDock.nama_dermaga }} <font-awesome-icon icon="fa-solid fa-map-marker-alt" /></a></span></p>
                </div>
                <div class="flex flex-col gap-1 text-xs font-medium mt-6 mx-4">
                  <div class="flex flex-row gap-2">
                    <font-awesome-icon icon="fa-solid fa-calendar-days" />
                    <p>Use on selected visit date</p>
                  </div>
                  <div class="flex flex-row gap-2">
                    <font-awesome-icon icon="fa-solid fa-phone" />
                    <p class="text-red-1">Need Confirmation</p>
                  </div>
                  <div class="flex flex-row gap-2">
                    <font-awesome-icon icon="fa-solid fa-hand-holding-dollar" />
                    <p>Refundable</p>
                  </div>
                </div>
                <button
                  class="bg-yellow-f6 hover:bg-yellow text-black font-medium text-sm rounded-md h-10 mx-4 mt-16 mb-4" @click="setPackage"
                >
                  Continue to Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import RentalEmpty from "../components/RentalEmpty.vue";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  components: { Header, Footer, RentalEmpty, VueTimepicker },
  data() {
    var now = new Date();
    return {
      showAccordion: {
        destination: true,
      },
      // selectedHour: 0,
      quantity: 0,
      availableStock: 0,
      namaWilayah: "",
      idWilayah: "",
      formData: {
        bookingDate: null,
        toko: null,
        id_dermaga: null,
        jam_awal: null,
        jam_akhir: null
      },
      selectedToko: {},
      selectedDock: {},
      attrs: [],
      listKota: [],
      listDock: [],
      listToko: [],
      listPaket: [],
      disabledDates: [],
      date: new Date(),
      timeStart: {
        HH: "09",
      },
      timeEnd: {
        HH: "10",
      },
    };
  },
  computed: {
    minDate() {
      var date = new Date();
      return date;
    },
    maxDate() {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      date.setMonth(date.getMonth() + 3);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    mapUrl(){
      return `https://www.google.com/maps/search/maps/@${this.selectedDock.latitude},${this.selectedDock.longitude},14z/data=!3m1!4b1`;
    },
  },
  created() {
    this.formData.jam_awal = this.$moment(`${this.timeStart.HH}:00`, "HH:mm");
    this.formData.jam_akhir = this.$moment(`${this.timeEnd.HH}:00`, "HH:mm");
    if(this.$route.params.id_wilayah != null){
      this.idWilayah = this.$route.params.id_wilayah;
      this.handleCityChange();
    }
    this.loadCity();
  },
  methods: {
    toggleDestinationAccordion() {
      this.showAccordion.destination = !this.showAccordion.destination;
    },
    updateCity(kota) {
      this.idWilayah = kota.id_wilayah;
      this.handleCityChange();
    },
    async selectDataToko(toko) {
      this.selectedToko = toko;
      this.formData.toko = this.selectedToko.id_toko_jetski_rental;
      this.formData.id_dermaga = this.selectedToko.data_dermaga?.id_dermaga;
      if(this.formData.bookingDate != null){
        this.loadPaket();
      }
    },
    minQty(det) {
      if (det?.qty === null) {
        det.qty = 0;
      } else {
        if (det.qty > 0) {
          det.qty--;
        } else {
          det.qty = 0;
        }
      }
    },
    addQty(det) {
      if (det?.qty === null) {
        det.qty = 0;
      } else {
        // det.qty++;
        let ttlstok = 0;
        for (const x in this.listPaket) {
          if (Object.hasOwnProperty.call(this.listPaket, x)) {
            const el = this.listPaket[x];
            ttlstok += parseInt(el.qty, 10);
          }
        }

        if (parseInt(det.stok, 10) > ttlstok) {
          det.qty++;
        } else {
          // det.qty--;
          this.$toasted.global.error(
            "Booked unit amount exceeding available unit for sweeper"
          );
        }
      }
    },
    async loadCity() {
      try {
        this.$store.commit("setOverlayLoading", true);

        const res = await this.$http.get(
          `${this.$apiTripweWeb}/wilayah/rental/list`,
          {
            params: {
              "latitude": this.$store.state.currentLocation.latitude ?? '-6.1946352',
              "longitude": this.$store.state.currentLocation.longitude ?? '106.8230873',
              "order_col": "nama_wilayah",
              "order_type": ""
            }
          }
        );
        this.listKota = res.data.data;
      } catch (error) {
        this.$toasted.global.error("Failed load city");
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async handleCityChange() {
      this.$store.commit("setIdWilayah", this.idWilayah);
      this.listPaket = [];
      this.formData.bookingDate = null;
      this.timeStart = {
        HH: "09"
      };
      this.timeEnd = {
        HH: "10"
      };
      this.formData.jam_awal = this.$moment(`${this.timeStart.HH}:00`, "HH:mm");
      this.formData.jam_akhir = this.$moment(`${this.timeEnd.HH}:00`, "HH:mm");

      await this.loadTokoRental();
    },
    async loadTokoRental() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var response = await this.$http.post(
          `${this.$apiTripweWeb}/package/rental/load-merchant`, {id_wilayah: this.idWilayah}
        );
        this.listToko = response.data.data;
      } catch (error) {
        this.$toasted.global.error("Failed load data store");
      } finally {
        if (this.listToko.length > 0) {
          if(this.$route.params.id_toko != null){
            this.selectedToko = this.listToko.find(d => d.id_toko_jetski_rental == this.$route.params.id_toko);
            if(this.selectedToko == null){
              this.selectedToko = this.listToko[0];
            }
          }else{
            this.selectedToko = this.listToko[0];
          }
          this.formData.toko = this.selectedToko.id_toko_jetski_rental;
          this.formData.id_dermaga = this.selectedToko.data_dermaga?.id_dermaga;
        }
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async loadCalendar() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const response = await this.$http.post(
          `${this.$apiTripweWeb}/calendars/rental/jetski`,
          {
            start_date:
              this.$moment(this.formData.bookingDate).format("YYYY-MM-DD") +
              " " +
              this.formData.jam_awal.format("HH:mm:00"),
            end_date:
              this.$moment(this.formData.bookingDate).format("YYYY-MM-DD") +
              " " +
              this.formData.jam_akhir.format("HH:mm:00"),
            store_id: this.formData.toko,
          }
        );
        if (response.data.code == "20000") {
          if (response.data.data.is_open != true) {
            this.formData.bookingDate = null;
            throw "Merchant is closed";
          }
          if (response.data.data.is_open == true) {
            this.availableStock = response.data.data.stock;
            await this.loadPaket();
          }
        }
      } catch (error) {
        if (error.response.status == "500") {
          this.$toasted.global.error("No available unit");
        } else {
          this.$toasted.global.error(error);
        }
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
      this.$store.commit("setOverlayLoading", false);
      return;
    },
    async loadPaket() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var tglAwal =
          this.$moment(this.formData.bookingDate).format("YYYYMMDD") +
          "" +
          this.formData.jam_awal.format("HHmm");
        var tglAkhir =
          this.$moment(this.formData.bookingDate).format("YYYYMMDD") +
          "" +
          this.formData.jam_akhir.format("HHmm");
        var res = await this.$http.post(
          `${this.$apiTripweWeb}/package/rental`,
          {
            toko: this.formData.toko,
            dermaga: this.formData.id_dermaga,
            tglawal: tglAwal,
            tglakhir: tglAkhir,
            limit: "",
          }
        );
        this.listPaket = res.data;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
      return;
    },
    validate(){
      let bookDate = this.$moment(this.formData.bookingDate);
      let curDate = this.$moment();
      var st = this.formData.jam_awal;
      var en = this.formData.jam_akhir;
      let openTime = this.$moment("08:00:00", "hh:mm:ss");
      let closeTime = this.$moment("18:00:00", "hh:mm:ss");

      // if(this.$store.state.user.id_user == this.selectedToko.id_toko_jetski_rental){
      //   return true;
      // }
      
      if (st.isAfter(en, "hour")) {
        this.$toasted.global.error(
          "Starting time cannot exceed ending time"
        );
        this.listPaket = [];
        return false;
      }

      if (openTime.isAfter(st, "hour") && (this.$store.state.user.id_user != this.selectedToko.id_toko_jetski_rental)) {
        this.$toasted.global.error(
          "Starting time available at 08:00, please change your starting time"
        );
        this.listPaket = [];
        return false;
      }
      if (en.isAfter(closeTime, "hour") && (this.$store.state.user.id_user != this.selectedToko.id_toko_jetski_rental)) {
        this.$toasted.global.error(
          "Ending time available at 18:00, please change your ending time"
        );
        this.listPaket = [];
        return false;
      }

      if (bookDate.isSame(curDate, "date")) {
        // this.$toasted.global.error("Same day");
        var dur = st.diff(curDate, "hours", true);
        if (curDate.isSameOrAfter(st, "hour")) {
          this.$toasted.global.error(
            "Starting time passed, please change your starting time"
          );
          this.listPaket = [];
          return false;
        }
        if (
          dur < 2 &&
          this.selectedToko.id_toko_jetski_rental != this.$store.state.user.id_user
        ) {
          this.$toasted.global.error(
            "Operator must be prepare at least 2 hours before unit used, please change your starting time"
          );
          this.listPaket = [];
          return false;
        }
      }
      return true;
    },
    async onDayClick() {
      if (
        this.formData.bookingDate != "" &&
        this.formData.bookingDate != null
      ) {
        let valid = this.validate();
        if (valid) {
          this.loadCalendar();
        }else{
          this.formData.bookingDate = null;
        }
      }
    },
    handleTimePicker(e, type){
      if(type == 'start'){
        this.formData.jam_awal = this.$moment(`${e.HH}:00`, "HH");

        if(this.formData.jam_awal.isSameOrAfter(this.formData.jam_akhir, "hour")){
          this.formData.jam_akhir = this.$moment(this.formData.jam_awal).add(1, 'hours');
          this.timeEnd.HH = this.formData.jam_akhir.format("HH");
        }
      }else{
        this.formData.jam_akhir = this.$moment(`${e.HH}:00`, "HH");

        if(this.formData.jam_awal.isSameOrAfter(this.formData.jam_akhir, "hour")){
          this.formData.jam_awal = this.$moment(this.formData.jam_akhir).subtract(1, 'hours');
          this.timeStart.HH = this.formData.jam_awal.format("HH");
        }
      }
      
      if (
        this.formData.bookingDate != "" &&
        this.formData.bookingDate != null
      ) {
        this.loadCalendar();
      }
    },
    
    setPackage() {
      var booked = [];
      for (const key in this.listPaket) {
        if (Object.hasOwnProperty.call(this.listPaket, key)) {
          const element = this.listPaket[key];
          if (element.qty > 0) {
            booked.push(element);
          }
        }
      }
      if (booked.length <= 0) {
        this.$toasted.global.error("Please select package first");
        return;
      }
      this.formData.selectedToko = this.selectedToko;
      this.$store.commit("setPackageList", booked);
      this.$store.commit("setBookRental", this.formData);
      this.$router.push({ 
        name: "CheckoutRental",
        params: { slug: "wateradventure" },
       });
    },
  },
};
</script>
